/* Donate.css */
.donate-page {
  padding: 40px 20px;
  background-color: #f8f9fa;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.donate-page h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #2c3e50;
  animation: slideDown 1s ease-out;
}

.donate-page p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #495057;
}

.donation-options {
  margin-bottom: 30px;
  animation: fadeIn 1.5s ease-in-out;
}

.donation-options h2 {
  margin-bottom: 20px;
  color: #2c3e50;
}

.amount-options {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.donation-amount {
  background-color: #27ae60;
  color: white;
  padding: 15px 30px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s, transform 0.3s;
}

.donation-amount:hover {
  background-color: #1e8449;
  transform: scale(1.1);
}

.custom-amount {
  padding: 15px;
  margin: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 1.2rem;
  width: 200px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.custom-amount:focus {
  border-color: #27ae60;
  box-shadow: 0 0 10px rgba(39, 174, 96, 0.5);
}

.payment-methods {
  margin-bottom: 30px;
  animation: fadeIn 2s ease-in-out;
}

.payment-methods h2 {
  margin-bottom: 20px;
  color: #2c3e50;
}

.methods {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.method-card {
  background-color: white;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  text-align: center;
  width: 150px;
}

.method-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.method-card img {
  max-width: 100%;
  height: 50px;
  margin-bottom: 10px;
}

.method-card p {
  font-size: 1rem;
  color: #2c3e50;
}

.donate-button {
  background-color: #27ae60;
  color: white;
  padding: 15px 30px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.donate-button:hover {
  background-color: #1e8449;
  transform: scale(1.1);
}
