.projects-section {
  padding: 60px 20px;
  background-color: #f8f9fa;
  text-align: center;
}

.projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 80%; /* Adjust the width to fit the container */
  max-width: 1200px; /* Set a max width for larger screens */
}

.project-card img {
  width: 50%; /* Ensure the image takes up half the container */
  height: auto; /* Maintain the image's aspect ratio */
  object-fit: cover; /* Crop the image to fit within the container */
}

.project-card-content {
  padding: 20px;
  width: 50%; /* Ensure the content takes up half the container */
  text-align: left;
}

.project-card-content h3 {
  font-size: 1.8rem;
  color: #343a40;
  margin-bottom: 10px;
}

.project-card-content p {
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 20px;
}

.project-card-content a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.project-card-content a:hover {
  color: #0056b3;
}
