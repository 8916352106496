/* Footer.css */
.footer {
  background-color: #2c3e50;
  color: white;
  padding: 50px 20px 20px;
  text-align: center;
  opacity: 0;
  transform: translateY(30px);
  animation: slideUp 1s ease-in-out forwards;
}

@keyframes slideUp {
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-about,
.footer-links,
.footer-social {
  flex: 1;
  min-width: 250px;
}

.footer h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #ecf0f1;
  animation: fadeIn 1.5s ease-in-out;
}

.footer-about p {
  font-size: 1rem;
  line-height: 1.6;
  color: #bdc3c7;
  animation: fadeIn 2s ease-in-out;
}

.footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-links ul li {
  margin-bottom: 10px;
  animation: fadeIn 2.5s ease-in-out;
}

.footer-links ul li a {
  color: #bdc3c7;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links ul li a:hover {
  color: #27ae60;
  transform: translateX(5px);
  transition: transform 0.3s ease-in-out;
}

.footer-social .social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  animation: fadeIn 3s ease-in-out;
}

.footer-social .social-icons a {
  color: white;
  font-size: 1.5rem;
  transition: transform 0.3s, color 0.3s;
}

.footer-social .social-icons a:hover {
  transform: scale(1.2);
  color: #27ae60;
}

.footer-bottom {
  margin-top: 30px;
  border-top: 1px solid #34495e;
  padding-top: 20px;
  font-size: 0.9rem;
  color: #95a5a6;
  opacity: 0;
  animation: fadeIn 3.5s ease-in-out forwards;
}

.footer-bottom p {
  margin: 0;
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@media (max-width: 768px) {
  .footer-container {
      flex-direction: column;
      align-items: center;
  }

  .footer-about,
  .footer-links,
  .footer-social {
      text-align: center;
  }
}
