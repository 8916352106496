/* Header.css */
.header {
  display: flex;
  justify-content: space-between; /* Space between logo/site name and menu */
  align-items: center; /* Center items vertically */
  padding: 10px 20px; /* Padding around the header */
  background-color: #f0f0f0; /* Dark background for the header */
  color: rgb(255, 255, 255); /* White text color */
}

.logo-container {
  display: flex;
  align-items: center; /* Center items vertically in the logo container */
}

.logo {
  width: 50px; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Space between logo and site name */
}

.site-name {
  font-size: 1.5em; /* Adjust size as needed */
  margin: 0; /* Remove default margin */
}

/* Navbar styles */
.navbar {
  display: flex; /* Use flexbox for horizontal alignment */
  justify-content: flex-end; /* Align menu to the far right */
  flex-grow: 1; /* Allow the navbar to grow and take up available space */
}

.navbar ul {
  list-style: none; /* Remove default list styles */
  display: flex; /* Use flexbox for horizontal alignment */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

.navbar li {
  margin-left: 20px; /* Space between menu items */
}

.navbar a {
  color: white; /* White text for links */
  text-decoration: none; /* No underline */
  font-size: 1em; /* Adjust font size */
}

.navbar a:hover {
  text-decoration: underline; /* Underline on hover */
}

/* Responsive styling */
@media (max-width: 600px) {
  .header {
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: flex-start; /* Align items to the left */
  }

  .navbar ul {
    flex-direction: column; /* Stack menu items vertically */
    width: 100%; /* Full width for mobile view */
  }

  .navbar li {
    margin: 5px 0; /* Space between vertical menu items */
  }

  .navbar {
    justify-content: flex-end; /* Keep navbar aligned to the right on mobile */
    width: 100%; /* Make sure it takes up full width */
  }

  .navbar a {
    padding: 10px 0; /* Increase padding for better touch targets */
    width: 100%; /* Full width for links */
    display: block; /* Make links block level */
  }
}
