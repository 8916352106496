/* Research.css */
.research-page {
  padding: 40px 20px;
  background-color: #f8f9fa;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

/* Fade-in animation for the entire research page */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.subheader {
  margin: 20px 0;
  animation: slideDown 1s ease-out;
}

/* Slide-down animation for the subheader */
@keyframes slideDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.categories {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  animation: fadeIn 1.5s ease-in-out;
}

.category {
  margin: 0 15px;
  padding: 10px 15px;
  background-color: #27ae60;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

/* Hover effect with scale animation for categories */
.category:hover {
  background-color: #1e8449;
  transform: scale(1.1);
}

.search-bar {
  margin-top: 10px;
  padding: 10px;
  width: 300px; /* Width of the search bar */
  border: 1px solid #ced4da;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

/* Focus effect with box shadow for the search bar */
.search-bar:focus {
  border-color: #27ae60; /* Change border color on focus */
  box-shadow: 0 0 10px rgba(39, 174, 96, 0.5);
}

.articles {
  max-width: 800px;
  margin: auto;
  animation: fadeIn 2s ease-in-out;
}

.article {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  margin: 10px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  opacity: 0;
  transform: translateY(20px);
  animation: articleFadeIn 1s forwards ease-in-out;
  animation-delay: 0.3s;
}

/* Scale and shadow effect on hover for articles */
.article:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Fade-in and slide-up animation for articles */
@keyframes articleFadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.author-date {
  font-size: 0.9rem;
  color: #6c757d;
}
