/* Header.css */
.header {
    display: flex;
    justify-content: space-between; /* Space between logo/site name and menu */
    align-items: center; /* Center items vertically */
    padding: 10px 20px; /* Padding around the header */
    background-color: #171404; /* Dark background for the header */
    color: rgb(0, 236, 90); /* White text color */
}

.logo-container {
    display: flex;
    align-items: center; /* Center items vertically in the logo container */
}

.logo {
    width: 50px; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
    margin-right: 10px; /* Space between logo and site name */
}

.site-name {
    font-size: 1.5em; /* Adjust size as needed */
    margin: 0; /* Remove default margin */
}

/* Navbar styles */
.navbar {
    display: flex; /* Use flexbox for horizontal alignment */
    margin-left: auto; /* Push navbar to the far right */
}

.navbar ul {
    list-style: none; /* Remove default list styles */
    display: flex; /* Use flexbox for horizontal alignment */
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
}

.navbar li {
    margin-left: 20px; /* Space between menu items */
}

.navbar a {
    color: white; /* White text for links */
    text-decoration: none; /* No underline */
    font-size: 1em; /* Adjust font size */
}

.navbar a:hover {
    text-decoration: underline; /* Underline on hover */
}

/* Responsive styling */
@media (max-width: 600px) {
    .header {
        flex-direction: column; /* Stack items vertically on small screens */
        align-items: flex-start; /* Align to the left */
    }

    .navbar {
        width: 10%; /* Make navbar full-width */
        justify-content: right; /* Align navbar to the left */
        margin-top: -60px; /* Space above navbar */
    }

    .navbar ul {
        flex-direction: column; /* Stack menu items vertically */
    }

    .navbar li {
        margin: 5px -100px; /* Space between vertical menu items */
    }
}
