/* Homepage.css */
.homepage {
  background-color: #f5f5f5;
  color: #2c3e50;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

/* Hero Section */
.hero-section {
  background: url('https://img.freepik.com/free-photo/realistic-water-drop-with-ecosystem_23-2151196455.jpg?t=st=1727359994~exp=1727363594~hmac=36c946e1d3a5250f9ab836c5e7e88710b68818e72a307d732d385f87ed474166&w=1380') no-repeat center center/cover;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
}

.hero-content {
  text-align: center;
  max-width: 600px;
}

.hero-content h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.cta-button {
  background-color: #27ae60;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #1e8449;
}

/* Initiatives Section Styles */
.initiatives-section {
  padding: 40px 20px;
  background-color: #f8f9fa;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.initiatives-carousel {
  overflow: hidden;
  position: relative;
}

.scroll-content {
  display: flex;
  animation: scroll 30s linear infinite;
}

.initiative-card {
  flex: 0 0 200px; /* Adjust width as needed */
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  margin: 0 10px;
  min-width: 200px;
}

/* Image styles for landscape images */
.initiative-card img {
  width: 100%; /* Make the image take the full width of the card */
  height: auto; /* Maintain the aspect ratio */
  border-radius: 10px; /* Optional: same border radius as the card */
  object-fit: cover; /* Ensures the image covers the card without distortion */
}

.initiative-card h3 {
  margin-top: 15px;
  font-size: 18px;
  color: #343a40;
}

.initiative-card p {
  color: #6c757d;
}

/* Continuous Scroll Animation */
@keyframes scroll {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-100%);
  }
}




/* About Section Styles */
.about-section {
    padding: 40px 20px;
    background-color: #e9ecef;
    text-align: center;
}

.about-content {
    display: flex;
    flex-direction: column; /* Change to row on larger screens */
    align-items: center;
    justify-content: center;
}

.about-text {
    max-width: 600px;
    margin: 20px;
}

.about-text h3 {
    margin: 15px 0;
    font-size: 24px;
    color: #343a40;
}

.about-text p {
    color: #495057;
    line-height: 1.5;
}

.about-text ul {
    list-style-type: none;
    padding: 0;
}

.about-text li {
    margin: 5px 0;
}

.about-image {
    margin-top: 20px;
}

.about-image img {
    max-width: 100%;
    border-radius: 8px;
}

/* Responsive styles */
@media (min-width: 768px) {
    .about-content {
        flex-direction: row; /* Change to row on larger screens */
        justify-content: space-between;
    }
    
    .about-text {
        margin-right: 40px; /* Spacing between text and image */
    }
}


/*About sectionh*/
.about-section {
  padding: 40px 20px;
  background-color: #f8f9fa; /* Light background color */
  text-align: center; /* Center text */
}

.about-content {
  display: flex;
  flex-direction: column; /* Stack vertically */
  align-items: center; /* Center items */
  justify-content: center; /* Center items */
}

.about-text {
  max-width: 600px; /* Adjust width as necessary */
  margin: 20px;
}

.about-image img {
  max-width: 100%; /* Responsive image */
  height: auto; /* Maintain aspect ratio */
}
