/* Contact.css */
.contact-page {
  padding: 40px 20px;
  background-color: #f0f4f8;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.contact-page h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #2c3e50;
  animation: slideIn 1s ease-out;
}

@keyframes slideIn {
  from {
      transform: translateY(-50px);
  }
  to {
      transform: translateY(0);
  }
}

.contact-page p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #495057;
}

.contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.contact-form,
.contact-info {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding: 30px;
  flex: 1;
  min-width: 300px;
  max-width: 500px;
  animation: fadeInUp 1.5s ease-in-out;
}

@keyframes fadeInUp {
  from {
      opacity: 0;
      transform: translateY(50px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.contact-form h2,
.contact-info h2 {
  margin-bottom: 20px;
  color: #2c3e50;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-size: 1rem;
  color: #495057;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #27ae60;
  box-shadow: 0 0 10px rgba(39, 174, 96, 0.5);
}

.submit-button {
  background-color: #27ae60;
  color: white;
  padding: 15px 30px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.submit-button:hover {
  background-color: #1e8449;
  transform: scale(1.05);
}

.contact-info p {
  font-size: 1rem;
  color: #495057;
  margin-bottom: 20px;
}

.map iframe {
  width: 100%;
  height: 300px;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
